<template>
  <v-tooltip top nudge-top="-10px">
    <template v-slot:activator="{on, attrs}">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="toggleTransportMode"
        icon
        class="my-0 py-0"
        :style="btnStyle">
        <v-img
          v-if="enabled"
          :src="iconEnabledURL"
          alt=""
          contain
          :style="mode.name === 'TRAMWAY' ? 'height: 33px !important; width: 33px !important;' : 'height: 30px !important; width: 30px !important;'"/>
        <v-img
          v-else
          :src="iconDisabledURL"
          alt=""
          contain
          :style="mode.name === 'TRAMWAY' ? 'height: 33px !important; width: 33px !important;' : 'height: 30px !important; width: 30px !important;'"/>
      </v-btn>
    </template>
    <span style="font-size: 14px;">{{$t(`view.modes.transports.${mode.name}`)}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "IconModePointDetails",

  props: {
    mode: {type: Object, default: () => null},
    enabled: {type: Boolean, default: false}
  },

  data() {
    return {
    }
  },

  created() {
    console.log(this.mode.name);
  },

  methods: {
    toggleTransportMode() {
      //console.log(!this.enabled);
      //this.enabled = !this.enabled;
      this.$emit('toggle');
    }
  },

  computed: {

    iconDisabledURL() {
      return this.mode.icon_disabled2 && require(`@/assets/icons/modes/${this.mode.icon_disabled2}`);
    },
    iconEnabledURL() {
      return this.mode.icon_disabled && require(`@/assets/icons/modes/${this.mode.icon_disabled}`);
    },

    btnStyle() {

      let style = "width: 50px !important; height: 50px !important;";
      /*
      if (this.enabled)
        return `${style} background-color: ${this.mode.color};`;

      return `${style} border: 1px solid ${this.mode.color};`;*/
      return style;
    },
  },
}
</script>

<style scoped>

</style>